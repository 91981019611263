import React, { Component } from 'react';
import countapi from 'countapi-js';

class VisitTuttiPost extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    countapi.visits('tutti').then((result) => {
      console.log(result)
      this.setState({ visiteTuttiPost: result.value });
    });
  }

  render() {

    return (
      <span style={{fontSize: '18px', marginBottom: '-20.5px'}} title="Numero delle visite sulla pagina 'Tutti i post'">(👁 {this.state.visiteTuttiPost})</span>
    );

  }
}

export default VisitTuttiPost;